import React, { useState, useEffect } from 'react';
import { Spin, message } from 'antd';
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal';
import { useIntl, getLocale } from 'umi';
import {
  cleanObject,
  eventDebounce,
  matchDynamicForm,
} from 'cognitiveleap-core-us/utils/utils';
import PaymentModal from './PaymentModal';

import { GetFilterCenterSubjectList } from 'services/userManager';
import {
  PostUserContract,
  PutUserContract,
  GetReasonQuestion,
} from 'services/userContract';

import { getAuthHeader } from 'cognitiveleap-core-us/utils/auth';

import config from 'utils/config';
const { baseURL } = config;

const uploadFileUrl = '/api/rocketAdmin/File/UserContract';

import moment from 'moment';

const fetch = async (value, callback) => {
  if (!value) return;

  const res = await GetFilterCenterSubjectList({
    filter: value,
  });

  if (res.response.ok) {
    callback(res?.data || []);
  } else {
    const {
      data: { error },
    } = res;
    message.error(error.message);
  }
};

const getDropdownOptions = (data) => {
  // 反馈多语言 匹配zh en key
  const language = getLocale() === 'zh-CN' ? 'zh' : 'en';

  const { options } = data.extraProperties[language] ?? data.extraProperties;

  return options.map((item) => {
    const { text, value } = item || {};
    return {
      Value: text,
      Id: value.toString(),
    };
  });
};

const AddContractModal = (props) => {
  const intl = useIntl();

  const {
    type = 'add',
    userData = {},
    contractDetail = {}, // 合同详情
    visible = false,
    closeModal = () => {},
    reloadPage = () => {},
  } = props;

  const { name, userId } = userData || {};

  const {
    contractType,
    discount,
    amount = 0,
    price = 1,
    id: contractId,
    totalClass = 1,
  } = contractDetail || {};

  const [contractAmount, setContractAmount] = useState(amount);
  const [contractSessionCount, setContractSessionCount] = useState(totalClass);
  const [paymentModalVisible, setPaymentModalVisible] = useState(false);
  const [currentAddContract, setCurrentAddContract] = useState({});

  const contentMenu = {
    Properties: [
      {
        EditorType: 'SingleWithSearch',
        ShowTitle: intl.formatMessage({ id: 'Child Name' }),
        FormKey: 'userId',
        AdditionalData: null,
        Value: userId,
        Setting: {
          DropdownOptions:
            Object.keys(userData).length > 0
              ? [
                  {
                    Value: name,
                    Id: userId.toString(),
                  },
                ]
              : [],
          Required: true,
          Disabled: userId,
          Props: {
            onSearch: (value) => {
              if (value) {
                eventDebounce(() => {
                  fetch(value, (searchItem) => {
                    let tempContent = { ...contentMenu };
                    tempContent.Properties[0].Setting.DropdownOptions =
                      searchItem.map((item) => {
                        const { name, id } = item || {};

                        return {
                          Value: name,
                          Id: id.toString(),
                        };
                      });

                    setModalContent(tempContent);
                  });
                }, 400);
              } else {
                let tempContent = { ...contentMenu };
                tempContent.Properties[0].Setting.DropdownOptions = [];
                setModalContent(tempContent);
              }
            },
          },
        },
      },
      {
        EditorType: 'Dropdown',
        ShowTitle: intl.formatMessage({ id: 'Contract Type' }),
        Value: null,
        Setting: {
          DropdownOptions: [
            {
              Value: intl.formatMessage({ id: 'Renewal' }),
              Id: 'Renewal',
            },
            {
              Value: intl.formatMessage({ id: 'Initial' }),
              Id: 'First',
            },
          ],
          Required: true,
          onchange: (value) => {
            let tempContent = { ...contentMenu };

            tempContent.Properties[8].Setting.IsVisible = value == 'Renewal';
            tempContent.Properties[9].Setting.IsVisible = value == 'First';

            setModalContent(tempContent);
          },
        },
        FormKey: 'contractType',
      },
      {
        EditorType: 'DatePicker',
        ShowTitle: intl.formatMessage({ id: 'Signing Date' }),
        FormKey: 'signTime',
        AdditionalData: null,
        Value: null,
        Setting: {
          Required: true,
          DateType: 'date',
          format: 'MM/DD/YYYY',
          DisabledDate: (current) => {
            return current && current > moment();
          },
        },
      },
      {
        EditorType: 'NumberInput',
        ShowTitle: intl.formatMessage({ id: 'Contract Amount' }),
        FormKey: 'amount',
        AdditionalData: null,
        Value: 0,
        Setting: {
          Required: true,
          Props: {
            min: 0,
            step: 1,
            onChange: (value) => {
              setContractAmount(value);
            },
          },
        },
        Description: '',
      },
      {
        EditorType: 'NumberInput',
        ShowTitle: intl.formatMessage({ id: 'Total Contract Session Fee' }),
        FormKey: 'totalClass',
        AdditionalData: null,
        Value: 1,
        Setting: {
          Required: true,
          Props: {
            min: 1,
            step: 1,
            onChange: (value) => {
              setContractSessionCount(value);
            },
          },
        },
        Description: '',
      },
      {
        EditorType: 'CustomRender',
        ShowTitle: intl.formatMessage({ id: 'Unit Session Fee' }),
        FormKey: 'price',
        AdditionalData: null,
        Setting: {
          Required: false,
          render: () => (
            <span>{price != 0 && amount != 0 ? (price).toFixed(2) : `0.00`}</span>
          ),
        },
        Description: '',
      },
      {
        EditorType: 'Bool',
        ShowTitle: intl.formatMessage({ id: 'Discount Applied' }),
        FormKey: 'discount',
        AdditionalData: null,
        Value: false,
        Setting: {
          Required: true,
          Props: {
            onChange: (value) => {
              let tempContent = { ...contentMenu };
              tempContent.Properties[7].Setting.IsVisible = value;
              setModalContent(tempContent);
            },
          },
        },
        Description: null,
      },
      {
        EditorType: 'CheckboxSelector',
        Value: [],
        Setting: {
          IsVisible: discount == true,
          DropdownOptions: [],
          Required: true,
        },
        ShowTitle: intl.formatMessage({ id: 'Discount Reason' }),
        FormKey: 'discountReason',
      },
      {
        EditorType: 'CheckboxSelector',
        Value: [],
        Setting: {
          IsVisible: contractType == 'Renewal',
          DropdownOptions: [],
          Required: true,
        },
        ShowTitle: intl.formatMessage({ id: 'Renewal Reason' }),
        FormKey: 'reason',
      },
      {
        EditorType: 'CheckboxSelector',
        Value: [],
        Setting: {
          IsVisible: contractType == 'First',
          DropdownOptions: [],
          Required: true,
        },
        ShowTitle: intl.formatMessage({ id: 'Signing Reason' }),
        FormKey: 'reason2',
      },
      {
        EditorType: 'Bool',
        ShowTitle: intl.formatMessage({ id: 'SMS Reminders' }),
        FormKey: 'remind',
        AdditionalData: null,
        Value: false,
        Setting: {
          Required: false
        },
        Description: intl.formatMessage({ id: 'remaining sessions' }),
      },
      {
        EditorType: 'Upload',
        Value: null,
        Setting: {
          UploadSetting: {
            // isSingle: true,
            uploadType: 'pdf',
          },
          Action: baseURL + uploadFileUrl,
          Header: getAuthHeader(),
          Required: false,
        },
        ShowTitle: intl.formatMessage({ id: 'Contract Attachment' }),
        FormKey: 'files',
        Description: intl.formatMessage({ id: 'availablePDFFormat' }),
      },
    ],
  };

  const [modalContent, setModalContent] = useState({});
  const [loading, setLoading] = useState({
    modalLoading: false,
    dataLoading: false,
  });

  const initData = async () => {
    setLoading({ ...loading, dataLoading: true });
    const res = await GetReasonQuestion();

    if (res.response.ok) {
      let tempContent = { ...contentMenu };

      const { signReason, renewalReason, discountReason } = res.data || {};

      tempContent.Properties[7].Setting.DropdownOptions =
        getDropdownOptions(discountReason);
      tempContent.Properties[8].Setting.DropdownOptions =
        getDropdownOptions(renewalReason);
      tempContent.Properties[9].Setting.DropdownOptions =
        getDropdownOptions(signReason);

      if (Object.keys(contractDetail || {}).length > 0) {
        tempContent = matchDynamicForm(contentMenu, contractDetail);
      }

      setModalContent(tempContent);
    }

    setLoading({ ...loading, dataLoading: false });
  };

  useEffect(() => {
    if (Object.keys(contractDetail || {}).length > 0) {
      const { amount = 0 } = contractDetail || {};

      setContractAmount(amount);
      setContractSessionCount(totalClass);
    }
  }, [contractDetail]);

  useEffect(() => {
    if (Object.keys(modalContent || {}).length > 0) {
      let tempContent = { ...modalContent };
      if (contractSessionCount && contractSessionCount > 0) {
        tempContent.Properties[5].Setting.render = () => (
          <span>{(contractAmount / contractSessionCount).toFixed(2)}</span>
        );
      } else {
        tempContent.Properties[5].Setting.render = () => (
          <span>{'0.00'}</span>
        );
      }

      setModalContent(tempContent);
    }
  }, [contractSessionCount, contractAmount]);

  useEffect(() => {
    if (type == 'add') {
      setContractSessionCount(1);
      setContractAmount(0);
    }

    if (visible) initData();
  }, [visible]);

  if (visible) {
    if (loading.dataLoading || Object.keys(modalContent || {}).length == 0)
      return <></>;
  }

  const modalProps = {
    title: intl.formatMessage({
      id: type == 'add' ? 'Add Contract' : 'Edit Contract',
    }),
    visible,
    content: modalContent,
    loading: loading.modalLoading,
    onSubmitText: intl.formatMessage({
      id: type == 'add' ? 'sureAdd' : 'submit',
    }),
    onCancel() {
      setModalContent(contentMenu);
      closeModal();
    },
    async onOk(value) {
      const { reason2, reason, price } = value;
      setLoading({
        ...loading,
        modalLoading: true,
      });

      const trimValue = cleanObject({
        ...value,
        reason: reason2 || reason,
        price: contractSessionCount
          ? (contractAmount / contractSessionCount).toFixed(2)
          : 0,
      });
      const res =
        type == 'edit'
          ? await PutUserContract({
              ...trimValue,
              id: contractId,
            })
          : await PostUserContract(trimValue);

      if (res.response.ok) {
        const { amount } = res.data;

        closeModal();
        reloadPage();

        if (type == 'add' && amount > 0) {
          setCurrentAddContract(res.data);
          setPaymentModalVisible(true);
        }
      } else {
        const { error } = res.data;
        message.error(error.message);
      }

      setLoading({
        ...loading,
        modalLoading: false,
      });
    },
  };

  const paymentModalProps = {
    userContractId: currentAddContract?.id,
    visible: paymentModalVisible,
    currentRecordItem: {},
    closeModal: () => {
      setPaymentModalVisible(false);
    },
    onReloadPage: reloadPage,
  };

  return (
    <Spin spinning={loading.dataLoading}>
      <MagicFormModal {...modalProps} />
      <PaymentModal {...paymentModalProps} />
    </Spin>
  );
};

export default React.memo(AddContractModal);
