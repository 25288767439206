import {
  CreateUserOfflinePlan,
  NewStartUserOfflinePlan,
  GetUserOfflinePlanForPunch,
  CurrentUserOfflinePlanList,
  PostFindUserOfflinePlanList,
  NextCourse,
  PostPunch,
  PutPunch,
  NotDoneItem,
  onGuardianNotDoItem,
  HasCClasssBeforeStartUserOfflinePlan,
  GetLatelyCourseApi,
  PostCourseAdjustmentApi,
  PostNumPunch,
  PutNumPunch,
  GetUserOfflinePlanForNumPunch,
  PostBeforeCreate,
  GetBrainRecord,
  PostEndPlanApi,
  PostArrangeAssessmentApi,
  GetArrangeAssessmentApi,
  PostMusicFeedback,
  PostCheckTryCourse,
  GetMusicRecord,
  PostAdjustProgramLevel,
  GoToNextCourse,
  GetParentalInterviewsNeed,
  PostExtendClass,
  DeleteUserOfflinePlan,
  PostRestartPlanApi,
  GetCategoryPunch,
  PostCategoryPunch,
  PutCategoryPunch,
} from 'services/rocketService/UserOfflinePlan'
import { GetCurrentMusicIndex } from 'services/music'
import { GetClassHoursSettingRecordsDetail } from 'services/userConfiguration'
import { GetCoachConfigDetail } from 'services/coachTrainingConfig';
import {
  GetDiagnosticInformationList,
  GetChiefComplaintList,
  GetTrainerDetail,
} from 'services/trainingSystem';
import {
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { GetSleepRecord } from 'services/rocketService/TrainingPlan';
import { message, Modal } from 'antd';
import { getDvaApp } from 'umi';
import {
  getNextKey,
  getDisabledValue,
  getHasDoneValue,
  getMusicHasDone,
  isParentalInterviewsNeed,
  showRemainingTimesMsg,
  showUserContractMsg,
  showCoachTimesMsg,
  isActiveCredentiales,
  isShowFASTip,
} from 'utils/utils';
import {
  GetTrainingCalendarList,
  PostArrangeCourse,
  PutCalendar,
  DeleteCalendar,
} from 'services/arrangeCourse';
import { GetSubjectLinkStaff } from 'services/userManager';
import { PostUserList } from 'services/userManager';
import constant from '../constant';
import moment from 'moment';
import { IntlManager } from 'utils/helper';
import { deepCopy } from 'cognitiveleap-core-us/utils/utils'

const getTimeSpan = (interval) => {
  switch (interval) {
    case '0':
      return null;
    case '1':
      return {
        periodIntervalUnit: 'Day',
        periodIntervalNumber: 1
      }
    case '2':
      return {
        periodIntervalUnit: 'Day',
        periodIntervalNumber: 7
      }
    case '3':
      return {
        periodIntervalUnit: 'Day',
        periodIntervalNumber: 14
      }
    case '4':
      return {
        periodIntervalUnit: 'Month',
        periodIntervalNumber: 1
      }
  }
}

// 获取重复排课
const getRepetitiveStyle = (periodIntervalNumber, periodIntervalUnit) => {
  if (periodIntervalNumber === 1 && periodIntervalUnit === "Day") {
    return "1"
  }
  if (periodIntervalNumber === 7 && periodIntervalUnit === "Day") {
    return "2"
  }
  if (periodIntervalNumber === 14 && periodIntervalUnit === "Day") {
    return "3"
  }
  if (periodIntervalNumber === 1 && periodIntervalUnit === "Month") {
    return "4"
  }
  return '0'
}

let contentMenu = () => {
  const formatMessage = IntlManager.intl.formatMessage
  return {
    Properties: [
      {
        EditorType: 'DatePicker',
        ShowTitle: formatMessage({ id: 'startTimefrom' }),
        FormKey: 'startTime',
        AdditionalData: null,
        Value: null,
        Setting: {
          format: 'YYYY-MM-DD HH:mm',
          minuteStep: 5,
          Required: true,
          DateType: 'time',
          disabledHours: [0, 1, 2, 3, 4, 5, 6, 7, 21, 22, 23],
          disabledTime: (time) => {
            const hour = moment(time).get("hour")
            if (hour === 20) {
              return {
                disabledMinutes: () => [30]
              }
            }
          },
          DisabledDate: (current) => {
            return current && current < moment().add(-1, 'day')
          }
        },
        Description: formatMessage({ id: 'durationHour' }),
      },
      {
        EditorType: 'SingleWithSearch',
        Value: null,
        Setting: {
          DropdownOptions: [],
          Required: true,
        },
        ShowTitle: formatMessage({ id: 'trainer' }),
        FormKey: 'trainer',
        Description: null,
      },
      {
        EditorType: 'Dropdown',
        Value: null,
        Setting: {
          DropdownOptions: [
            {
              Value: formatMessage({ id: 'offline' }),
              Id: 'Offline',
            },
            {
              Value: formatMessage({ id: 'online' }),
              Id: 'Online',
            },
          ],
          Required: true,
        },
        ShowTitle: formatMessage({ id: 'type' }),
        FormKey: 'courseType',
        Description: null,
      },
      {
        EditorType: 'Dropdown',
        Value: null,
        Setting: {
          IsVisible: true,
          DropdownOptions: [
            {
              Value: formatMessage({ id: 'never' }),
              Id: '0',
            },
            // {
            //     Value: '每天',
            //     Id: '1',
            // },
            {
              Value: formatMessage({ id: 'oneWeek' }),
              Id: '2',
            },
            {
              Value: formatMessage({ id: 'twoWeeks' }),
              Id: '3',
            },
            {
              Value: formatMessage({ id: 'oneMonth' }),
              Id: '4',
            },
          ],
          onchange(value) {
            // 如果设置为不重复，则隐藏重复次数选项
            const data = getDvaApp()._store.getState().subjectOfflinePlan
            const { arrangeCourseContent } = data
            const content = deepCopy(arrangeCourseContent)
            if (value == 0) {
              content.Properties[4].Setting.IsVisible = false;
              getDvaApp()._store.dispatch({
                type: 'subjectOfflinePlan/updateState',
                payload: {
                  arrangeCourseContent: content
                }
              })
            } else {
              content.Properties[4].Setting.IsVisible = true;
              getDvaApp()._store.dispatch({
                type: 'subjectOfflinePlan/updateState',
                payload: {
                  arrangeCourseContent: content
                }
              })
            }
          },
          Required: true,
        },
        ShowTitle: formatMessage({ id: 'repeat' }),
        FormKey: 'interval',
        Description: null,
      },
      {
        EditorType: 'NumberInput',
        ShowTitle: formatMessage({ id: 'numberOfRepetitions' }),
        FormKey: 'courseCount',
        AdditionalData: null,
        Value: 1,
        Setting: {
          IsVisible: true,
          NumberSetting: {
            min: 1,
            step: 1,
          },
          Required: true,
        },
        Description: formatMessage({ id: 'enterAnIntegerGreater' }),
      },
      {
        EditorType: 'CheckboxSelector',
        Value: ['parentSMS', 'trainerSMS'],
        Setting: {
          DropdownOptions: [
            {
              Value: formatMessage({ id: 'trainer' }),
              Id: 'trainerSMS'
            },
            {
              Value: formatMessage({ id: 'guardian' }),
              Id: 'parentSMS'
            }
          ],
          Required: false,
        },
        ShowTitle: formatMessage({ id: 'Schedule Reminder' }),
        FormKey: 'SMSReminder',
        Description: formatMessage({ id: 'reminderDes' }),
      },
      {
        EditorType: 'Bool',
        Value: true,
        Setting: {},
        ShowTitle: formatMessage({ id: 'Guardians Punch Reminder' }),
        FormKey: 'parentAppNotice',
        Description: '',
      }
    ]
  }
}

export default {
  namespace: 'subjectOfflinePlan',

  state: {
    currentlist: [],
    allList: [],
    pagination: {
      current: 1,
      pageSize: 6,
      total: 0,
    },
    isCurrentNum: true,
    planTypeModalVisible: false,
    drawerVisible: false,
    selectCourseInfo: null,
    ilsDrawerVisible: false,
    currentIlsId: null,
    newPlanLoading: false,
    currentonlinePlanId: null,
    ilsActiveKey: 'chart',
    ilstrainerRecord: null,
    ilsparentRecord: null,
    ilsappRecord: null,
    shouldGetData: false,
    addCClassChecked: false,
    classAdjustmentModalVisible: false,
    adjustLevelModalVisible: false,
    addClassType: 'addcclass', // 调整课程的类型
    choicedCClass: [], // 穿梭框右边的课程，也是需要提交到后端的C课程
    currentAdjustmentCourseInfo: null, // 当前要调整课程的课程信息
    latelyCourse: null,   // 获取最近上的课程和C课程
    selectGuardianCourseInfo: null,
    selectCategoryInfo: [],
    musicPlayerDrawerVisible: false,
    currentMusicPlanInfo: null,   // 当前音乐信息
    brainModalVisible: false,
    brainType: null,
    currentType: null,
    brainRecord: null,
    audioType: '',
    selectAddjustLevelUserOfflinePlan: null,
    arrangeAssessmentModalVisible: false,  // 安排测评弹框显示
    currentArrangeAssessmentPlan: null,    // 当前安排测评的计划信息
    endPlanModalVisible: false,  // 结束计划弹框显示
    currentEndPlan: null,   //当前结束的计划信息
    arrangeAssessmentData: null,
    currentTablePlan: null, // 当前被点击的表格item
    videoVisible: false,
    currentProgramId: null,
    recordPagination: {
      ilstrainer: {
        current: 0,
        pageSize: 10,
      },
      ilsparent: {
        current: 0,
        pageSize: 10,
      },
      ilsapp: {
        current: 0,
        pageSize: 10,
      }
    },
    musicRecord: null,    // 音乐打卡记录
    activeKey: [],
    // 排课Drawer
    arrangeCourseDrawerVisible: false,
    // 当前需要排课的plan
    currentArrangeCoursePlanInfo: null,
    // 为当前计划排课的弹框显示
    arrangeCourseModalVisible: false,
    //生成个性化课程modal
    generatorModalVisible: false,
    // 排课弹框字段
    arrangeCourseContent: null,
    // 记录当前点击的是添加还是编辑
    arrangeCourseType: '',
    // 已经给当前计划安排课程的list
    arrangeCourseList: [],
    // 当前编辑的课时
    currentEditCourse: null,
    endCourseLoading: false,
    offlinePlanNum: {
      remainingTimes: null,
      totalTimes: null
    },
    // 续课Modal
    extendModalVisible: false,
    // 选中的当前课时
    currentClassItem: null,
    trainModalVisible: false,
    independentCoachConfig: {},
    trainerCredentialses: [], // 颁发的证书列表
    submitAdjustLevelLoading: false,
    allowReloadTab: false, // 是否允许刷新tab
    checkEndPlanVisible: false, // 结束计划弹框确认
  },

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },

  effects: {
    *loadList({ payload }, { all, call, put, take, select }) {
      yield put({
        type: 'updateState',
        payload: {
          arrangeCourseContent: deepCopy(contentMenu()),
        },
      })
      const { subjectInfo } = yield select(state => state.subjectDetail)
      const { pagination } = yield select(state => state.subjectOfflinePlan)
      const { isOpen } = yield select(state => state.musicplayer)
      const { currentUser } = yield select(state => state.user)
      const { id, current } = currentUser || {}
      const { roles = [], userId } = current || {}
      const isIndependentCoach = roles.some(role => role.name === 'IndependentCoach')

      const currentPageIndex = pagination.current
      const currentPageSize = pagination.pageSize

      const parameters1 = {
        subjectId: subjectInfo.id,
      }

      const parameters2 = {
        subjectId: subjectInfo.id,
        skipCount: (currentPageIndex - 1) * currentPageSize,
        maxResultCount: currentPageSize,
        status: ["Ended"]
      }

      const parameters3 = {
        userId: subjectInfo.id,
      }

      const parameters4 = {
        trainerId: id
      }

      const parameters5 = {
        userId,
      }

      const [res1, res2, res3, res4, res5] = yield all([
        call(CurrentUserOfflinePlanList, parameters1),
        call(PostFindUserOfflinePlanList, parameters2),
        call(GetClassHoursSettingRecordsDetail, parameters3),
        call(GetTrainerDetail, parameters4),
        isIndependentCoach && call(GetCoachConfigDetail, parameters5)
      ])

      if (res1.response.ok) {
        yield put({
          type: 'updateState',
          payload: {
            currentlist: res1.data,
          },
        })

        const musicProgressResList = yield all(res1.data.map(item => {
          const { num, userOfflineTrainingPlan } = item || {}
          const { id } = userOfflineTrainingPlan || {}
          const params = {
            UserId: subjectInfo.id,
            UserOfflinePlanId: id,
            Num: num,
            Platform: 'Web',
          };
          return call(GetCurrentMusicIndex, params)
        }));

        if (musicProgressResList.every(item => item.response.ok)) {
          const newCurrentList = res1.data.map((item, index) => {
            return {
              ...item,
              musicProgress: {
                ...musicProgressResList[index].data
              }
            }
          });

          yield put({
            type: 'updateState',
            payload: {
              currentlist: newCurrentList,
            },
          })
        }
      }

      if (isOpen) {
        yield put({ type: 'updateCurrentlist' })
      }

      if (res2.response.ok) {
        const { items, totalCount } = res2.data
        yield put({
          type: 'updateState',
          payload: {
            pagination: { ...pagination, total: totalCount },
            allList: items,
          },
        })
      }

      if (res3.response.ok) {
        const { remainingTimes, totalTimes } = res3.data
        yield put({
          type: 'updateState',
          payload: {
            offlinePlanNum: { remainingTimes, totalTimes },
          },
        })
      }

      if (res4.response.ok) {
        const { trainerCredentialses } = res4.data || {};
        yield put({
          type: 'updateState',
          payload: {
            trainerCredentialses
          },
        })
      }

      if (res5 && res5.response.ok) {
        yield put({
          type: 'updateState',
          payload: {
            independentCoachConfig: res5.data
          },
        })
      }
    },

    *loadMusicProgress({ payload }, { all, call, put, take, select }) {
      const {
        UserId,
        UserOfflinePlanId,
        Num
      } = payload || {};
      const { currentlist } = yield select(state => state.subjectOfflinePlan);
      const selectTrainIndex = currentlist.findIndex(item => item.userOfflineTrainingPlan.id == UserOfflinePlanId);
      const selectTrainData = currentlist[selectTrainIndex] || {};
      let newCurrentList = [...currentlist];

      const params = {
        UserId,
        UserOfflinePlanId,
        Num,
        Platform: 'Web',
      };
      const res = yield call(GetCurrentMusicIndex, params);

      if (res.response.ok) {
        newCurrentList[selectTrainIndex] = {
          ...selectTrainData,
          musicProgress: {
            ...res.data
          }
        }

        yield put({
          type: 'updateState',
          payload: {
            currentlist: newCurrentList,
          },
        })
      }
    },

    *changeTable({ payload: data }, { call, put, select }) {
      const { pagination: originPage } = yield select(state => state.subjectOfflinePlan)
      const { pagination } = data
      yield put({ type: 'updateState', payload: { pagination: { ...originPage, ...pagination } } })
      yield put({ type: 'loadList' })
    },

    *createOfflinePlan({ payload: { data, intl } }, { call, put, select }) {
      const res = yield call(CreateUserOfflinePlan, data)
      yield put({ type: 'updateState', payload: { planTypeModalVisible: false } })
      constant.isCreatePlanLoading = false
      if (res.response.ok) {
        message.success(intl.formatMessage({ id: 'arrangeSuccess' }))
        yield put({
          type: 'loadList',
        })
      } else {
        const { error } = res.data;
        const { code } = error || {}
        if (code === "SubjectNotFound") {
          message.error(intl.formatMessage({ id: 'subjectNotFound' }))
        } else {
          Modal.warning({
            title: intl.formatMessage({ id: 'reminder' }),
            content: error.message,
            okText: intl.formatMessage({ id: 'know' })
          })
        }
      }
    },

    *onFeedback({ payload: data }, { call, put, select }) {
      const { userPlanId, num, id } = data
      const res = yield call(GetUserOfflinePlanForPunch, {
        UserOfflinePlanId: userPlanId,
        num,
      })
      if (res.response.ok) {
        const { planItems = [], nonePlaybook = [] } = res.data || {}

        const categoryDisabled = planItems.some(item => item.hasDone == null) || nonePlaybook.some(item => item.hasDone == null)

        if (!categoryDisabled) {
          yield put({
            type: 'onCategoryFeedback',
            payload: { userPlanId, num }
          })
        }

        yield put({
          type: 'updateState',
          payload: { drawerVisible: true, selectCourseInfo: res.data },
        })
        yield put({
          type: 'findActiveKeys',
          payload: { type: 'training', id }
        })
      }
    },

    *startOfflinePlan({ payload: { reloadPage, values } }, { call, put, select }) {
      const res = yield call(NewStartUserOfflinePlan, values);
      if (res.response.ok) {
        reloadPage();
        yield put({
          type: 'loadList',
        })
      } else {
        const { error } = res.data
        message.error(error.message)
      }
    },

    // *startOfflinePlan({ payload: { record, intl, preAssessmentId } }, { call, put, select }) {
    //   const { id, qualificationMedals = [] } = record || {}
    //   const { addCClassChecked, independentCoachConfig, trainerCredentialses } = yield select(state => state.subjectOfflinePlan);

    //   yield put({
    //     type: 'updateState',
    //     payload: { generatorModalVisible: true },
    //   })

    //   const res = yield call(NewStartUserOfflinePlan, {
    //     planId: id,
    //     preAssessmentId,
    //     addCCourse: addCClassChecked
    //   });
    //   if (res.response.ok) {
    //     const { currentUser } = yield select(state => state.user)
    //     const { current } = currentUser || {}
    //     const { roles = [] } = current || {}
    //     const { usedTimes = 0, totalTimes = 0 } = independentCoachConfig || {}
    //     const isIndependentCoach = roles.some(role => role.name === 'IndependentCoach')
    //     const { offlinePlanNum: { remainingTimes } } = yield select(state => state.subjectOfflinePlan);
    //     const cardStatus = isActiveCredentiales(qualificationMedals, trainerCredentialses)
    //     const coachShowToast = cardStatus === 'Active' || cardStatus === 'NotActive' || cardStatus === 'Expired'

    //     if (coachShowToast && isIndependentCoach) {
    //       if (cardStatus === 'Expired') {
    //         message.warning('您的证书已过期，请联系您的培训负责人')
    //       } else {
    //         const freeTimes = totalTimes - usedTimes;
    //         if (showCoachTimesMsg(intl, freeTimes, 'submit')) return;
    //       }
    //     }
    //     if (showRemainingTimesMsg(intl, remainingTimes, currentUser, 'offlinePlan', 'submit', () => getDvaApp()._store.dispatch({ type: 'subjectOfflinePlan/goSettings' }))) {
    //       yield put({
    //         type: 'updateState',
    //         payload: { generatorModalVisible: false },
    //       });
    //       return;
    //     };

    //     yield put({
    //       type: 'loadList',
    //     })
    //   }
    //   else {
    //     const { error } = res.data
    //     message.error(error.message)
    //   }

    //   yield put({
    //     type: 'updateState',
    //     payload: { generatorModalVisible: false },
    //   })
    // },

    *closeDrawer({ payload }, { call, put, select }) {
      const { intl } = payload
      yield put({
        type: 'updateState',
        payload: { drawerVisible: false, selectCategoryInfo: [] },
      })
      yield put.resolve({
        type: 'loadList',
      })
      // 找到对应课最新的状态, 所有课时完成自动结束课时
      const { selectCourseInfo, currentlist } = yield select(state => state.subjectOfflinePlan);
      const alldata = currentlist.find(item => item.nonePlaybook[0].id === selectCourseInfo.nonePlaybook[0].id)

      const { userOfflineTrainingPlan, num, planItems, nonePlaybook, numPunchOutput, categoryPunch } = alldata
      const { feedbacks: categoryFeedbacks } = categoryPunch || {}
      const hasCategoryFeedbacks = Object.keys(categoryFeedbacks || {}).length > 0

      const { feedbacks: guardianFeedback, hasDone: guardianHasDone, audioRecordPunch } = JSON.parse(JSON.stringify(numPunchOutput))
      let musicFeedBackFlag = true
      const guardianFeedbackFlag = guardianHasDone == false ? true : guardianFeedback.length > 0
      if (audioRecordPunch) {
        if (!audioRecordPunch.punched) {
          musicFeedBackFlag = false
        }
      }
      if (planItems.every(item => item.hasDone !== null) && nonePlaybook.every(item => item.hasDone !== null) && guardianFeedbackFlag && musicFeedBackFlag && hasCategoryFeedbacks) {
        const { id: userPlanId, totalNum } = userOfflineTrainingPlan;
        // 最后一节课关闭训练计划使用
        if (num === totalNum) {
          yield put({
            type: 'updateState',
            payload: {
              currentEndPlan: userOfflineTrainingPlan
            }
          })
        }
        yield put({
          type: 'checkTryCourse',
          payload: { userPlanId, num, totalNum, intl }
        })
      }
    },

    *getRecord({ payload: { id, type, current } }, { all, call, put, take, select }) {
      const { ilstrainerRecord, ilsparentRecord, ilsappRecord, recordPagination } = yield select(state => state.subjectOfflinePlan);
      const { subjectId } = yield select(state => state.subjectDetail);
      const idFieldName = type == 'ilsapp' ? 'UserTrainingPlanId' : "UserOfflineTrainingPlanId";
      const parmasData = {
        SubjectId: subjectId,
        [idFieldName]: id,
        SkipCount: (current - 1) * (recordPagination[type].pageSize),
        MaxResultCount: recordPagination[type].pageSize
      };
      type != 'ilsapp' && (parmasData.ForGuardian = (type == 'ilsparent'));
      const res = yield call(GetSleepRecord, parmasData);
      if (res.response.ok) {
        const totalCount = res.data.totalCount;
        const newPagination = Object.assign({}, recordPagination, { [type]: { current, pageSize: recordPagination[type].pageSize } });
        switch (type) {
          case 'ilstrainer':
            yield put({
              type: 'updateState', payload: {
                ilstrainerRecord: ilstrainerRecord && totalCount ? { items: ilstrainerRecord.items.concat(res.data.items), totalCount } : res.data,
                recordPagination: newPagination,
              }
            });
            break;
          case 'ilsparent':
            yield put({
              type: 'updateState', payload: {
                ilsparentRecord: ilsparentRecord && totalCount ? { items: ilsparentRecord.items.concat(res.data.items), totalCount } : res.data,
                recordPagination: newPagination,
              }
            });
            break;
          case 'ilsapp':
            yield put({
              type: 'updateState', payload: {
                ilsappRecord: ilsappRecord && totalCount ? { items: ilsappRecord.items.concat(res.data.items), totalCount } : res.data,
                recordPagination: newPagination,
              }
            });
            break;
        }
      }
    },

    *submitFeedback({ payload }, { call, put, select }) {
      const { planItemId, hasDone } = payload
      // 如果hasDone==null代表的是从未提交过，使用PostPunch，如果是修改就使用PutPunch
      const res = yield call(hasDone == null ? PostPunch : PutPunch, payload)
      if (res.response.ok) {
        const { userOfflineTrainingPlanId, num } = res.data
        yield put({
          type: 'onFeedback',
          payload: { userPlanId: userOfflineTrainingPlanId, num, id: planItemId },
        })
      } else {
        const { error } = res.data || {}
        message.error(error.message)
      }
    },

    *submitCategoryFeedback({ payload }, { call, put, select }) {
      const { hasDone, num, userOfflineTrainingPlanId } = payload
      // 如果hasDone==null代表的是从未提交过，使用PostPunch，如果是修改就使用PutPunch
      const res = yield call(hasDone == null ? PostCategoryPunch : PutCategoryPunch, payload)
      if (res.response.ok) {
        yield put({
          type: 'onCategoryFeedback',
          payload: { userPlanId: userOfflineTrainingPlanId, num },
        })
      } else {
        const { error } = res.data || {}
        message.error(error.message)
      }
    },

    *onNotDoItem({ payload: id }, { call, put, select }) {
      const res = yield call(NotDoneItem, id)
      if (res.response.ok) {
        const { userOfflineTrainingPlanId, num } = res.data
        yield put({
          type: 'onFeedback',
          payload: { userPlanId: userOfflineTrainingPlanId, num, id },
        })
      } else {
        const { error } = res.data || {}
        message.error(error.message)
      }
    },

    *onAdjustLevel({ payload: data }, { call, put, select }) {
      yield put({
        type: 'updateState',
        payload: {
          adjustLevelModalVisible: true,
          selectAddjustLevelUserOfflinePlan: data,
        }
      })
    },

    // *changeNum({ payload: data }, { call, put, select }) {
    //   const { num, userOfflinePlanId } = data
    //   const { currentlist } = yield select(state => state.subjectOfflinePlan)
    //   const params = {
    //     num,
    //     userOfflinePlanId
    //   }
    //   const res = yield call(GetPlanItemDetail, params)
    //   if (res.response.ok) {
    //     let tempContent = JSON.parse(JSON.stringify(currentlist))

    //     const index = tempContent.findIndex(item => item.userOfflineTrainingPlan.id === res.data.userOfflineTrainingPlan.id)
    //     tempContent.splice(index, 1, res.data)
    //     yield put({
    //       type: 'updateState',
    //       payload: {
    //         currentlist: tempContent,
    //       },
    //     })
    //   }
    // },

    *deleteTrain({ payload: { record, intl } }, { call, put, select }) {
      const { id } = record || {};
      const res = yield call(DeleteUserOfflinePlan, { userOfflineTrainingPlanId: id });
      if (res && res.response && res.response.ok) {
        message.success(intl.formatMessage({ id: 'success' }))
        yield put({
          type: 'loadList',
        })
      }
      else {
        const { data: { error } } = res;
        message.error(error.message);
      }
    },

    *submitAdjustLevel({ payload: data }, { call, put, select }) {
      const intl = IntlManager.MyIntl()

      yield put({
        type: 'updateState',
        payload: {
          submitAdjustLevelLoading: true
        }
      })

      const { selectAddjustLevelUserOfflinePlan, allowReloadTab } = yield select(state => state.subjectOfflinePlan)
      const { userOfflineTrainingPlan: { id: userOfflinePlanId } } = selectAddjustLevelUserOfflinePlan
      const postData = {
        userOfflinePlanId,
        ...data
      }
      const res = yield call(PostAdjustProgramLevel, postData)
      if (res && res.response && res.response.ok) {
        message.success(intl('success'))
        yield put({
          type: 'updateState',
          payload: {
            adjustLevelModalVisible: false,
            allowReloadTab: !allowReloadTab
          }
        })
        yield put({
          type: 'loadList',
        })
      }
      else {
        const { data: { error } } = res;
        message.error(error.message);
      }

      yield put({
        type: 'updateState',
        payload: {
          submitAdjustLevelLoading: false
        }
      })
    },

    *onGuardianNotDoItem({ payload: { offlinePlanId, num, hasDone, notFinishAudios } }, { call, put, select }) {
      const res = yield call(onGuardianNotDoItem, {
        offlinePlanId,
        num,
        hasDone,
        notFinishAudios
      })
      if (res.response.ok) {
        yield put.resolve({
          type: 'onFeedback',
          payload: {
            userPlanId: offlinePlanId,
            num
          }
        })
        yield put.resolve({
          type: 'onGuardianFeedback',
          payload: {
            userPlanId: offlinePlanId,
            num
          },
        })
      }
    },

    *tipParentInterviewImplementation({ payload }, { call, put, select }) {
      const { isEndLastClass, intl } = payload
      if (isEndLastClass) {
        Modal.confirm({
          title: intl.formatMessage({ id: 'trainingPeriodEnd' }),
          okText: intl.formatMessage({ id: 'Add Sessions Class' }),
          cancelText: intl.formatMessage({ id: 'overPlan' }),
          onCancel() {
            getDvaApp()._store.dispatch({ type: 'subjectOfflinePlan/updateState', payload: { endPlanModalVisible: true } })
          },
          onOk() {
            getDvaApp()._store.dispatch({ type: 'subjectOfflinePlan/updateState', payload: { extendModalVisible: true } })
          }
        })
      } else {
        Modal.confirm({
          title: intl.formatMessage({ id: 'activateNextSessionDes' }),
          okText: intl.formatMessage({ id: 'sureActivate' }),
          cancelText: intl.formatMessage({ id: 'cancelLater' }),
          onOk() {
            getDvaApp()._store.dispatch({ type: 'subjectOfflinePlan/startNextCourse', payload })
          }
        })
      }
    },

    *nextTipParentInterview({ payload }, { call, put, select }) {
      const { userPlanId, num, intl } = payload
      const { subjectId, subjectInfo: { name } } = yield select(state => state.subjectDetail);
      const res = yield call(GetParentalInterviewsNeed, {
        UserOfflinePlanId: userPlanId,
        Num: num,
        SubjectId: subjectId
      })
      if (res.response.ok) {
        const { need } = res.data
        if (need) {
          Modal.warning({
            title: intl.formatMessage({ id: 'shouleParentInterview' }, { name }),
            content: null
          })
        } else {
          yield put({
            type: 'tipParentInterviewImplementation',
            payload
          })
        }
      }
    },

    *tipParentInterview({ payload }, { call, put, select }) {
      const { intl } = payload
      Modal.warning({
        title: intl.formatMessage({ id: 'completeInterviewPoperly' }),
        content: <div style={{ color: 'gray', fontSize: '14px' }}>{intl.formatMessage({ id: 'NoteParentsInterview' })}</div>,
        onOk() {
          getDvaApp()._store.dispatch({
            type: 'subjectOfflinePlan/tipParentInterviewImplementation',
            payload
          })
        }
      })
    },

    *onFinishCourse({ payload }, { call, put, select }) {
      const { userPlanId, num, totalNum, intl } = payload

      const { N, isSameN, needTip } = isShowFASTip(num);

      const { subjectId, subjectInfo } = yield select(state => state.subjectDetail);
      const { currentlist } = yield select(state => state.subjectOfflinePlan);

      const findItem = currentlist.find(item => {
        const { userOfflineTrainingPlan } = item || {}
        const { id } = userOfflineTrainingPlan || {}
        return userPlanId === id
      })

      const { userOfflineTrainingPlan } = findItem || {}
      const { assessmentStatus = [], userId } = userOfflineTrainingPlan || {}
      const { stage, name } = subjectInfo

      const isEndLastClass = totalNum === num // 最后一节课

      const res = yield call(NextCourse, { offlinePlanId: userPlanId, num })
      if (res.response.ok) {
        message.success(intl.formatMessage({ id: 'thisSessionEnded' }))

        getDvaApp()._store.dispatch({
          type: 'subjectDetail/loadPage',
          payload: {
            entry: "path",
            id: subjectId
          }
        })

        if (needTip && isSameN === 'same') {
          Modal.warning({
            title: intl.formatMessage({ id: 'FAS retest' }, { num: N*40+1 }),
            content: '',
            okText: intl.formatMessage({ id: 'evaluationQrCode' }),
            onOk() {
              const { assessment, userAssessment } =
                assessmentStatus[0] || {};
              let _userAssessment = {
                ...userAssessment,
              };
              const { id: assessmentId, title } =
                assessment || {};
              delete _userAssessment?.id;
              getDvaApp()._store.dispatch({
                type: 'subjectAssessment/getAssessmentQrCode',
                payload: {
                  record: {
                    ..._userAssessment,
                    assessmentName: title,
                    assessmentId,
                    userId
                  },
                  intl,
                  from: 'Retest'
                },
              })
            }
          });
        } else {
          if (isParentalInterviewsNeed(num) && stage !== '黄金时代') {
            const res = yield call(GetParentalInterviewsNeed, {
              UserOfflinePlanId: userPlanId,
              Num: num,
              SubjectId: subjectId
            })
            if (res.response.ok) {
              const { need } = res.data
              if (need) {
                if (isParentalInterviewsNeed(num, 'next')) {
                  Modal.warning({
                    title: intl.formatMessage({ id: 'shouleParentInterview' }, { name }),
                    content: null
                  })
                } else {
                  yield put({
                    type: 'tipParentInterview',
                    payload: {
                      ...payload,
                      isEndLastClass,
                    }
                  })
                }
              } else {
                yield put({
                  type: 'tipParentInterviewImplementation',
                  payload: {
                    ...payload,
                    isEndLastClass
                  }
                })
              }
            }
          } else {
            yield put({
              type: 'tipParentInterviewImplementation',
              payload: {
                ...payload,
                isEndLastClass
              }
            })
          }
        }

        yield put({
          type: 'loadList',
        })
      } else {
        const { data: { error } } = res;
        message.error(error.message);
      }
    },

    *finishCourse({ payload }, { call, put, select }) {
      const { num, intl } = payload

      const { N, isSameN, needTip } = isShowFASTip(num);

      if (needTip && isSameN === 'before') {
        Modal.warning({
          title: intl.formatMessage({ id: 'The FAS retest' }, { firstNum: N*40, lastNum: N*40+1 }),
          content: <span style={{ color: 'red', fontWeight: 'bold', fontSize: '15px' }}>
            {intl.formatMessage({ id: 'fasRetestTip' })}
          </span>,
          okText: intl.formatMessage({ id: 'fasOk' }),
          width: '500px',
          onOk() {
            getDvaApp()._store.dispatch({
              type: 'subjectOfflinePlan/onFinishCourse',
              payload
            })
          }
        });
      } else {
        yield put({
          type: 'onFinishCourse',
          payload
        })
      }
    },

    *startNextCourse({ payload }, { call, put, select }) {
      const { userPlanId, num, intl } = payload
      const { currentUser } = yield select(state => state.user)
      const { classAndMusicInfo, subjectInfo } = yield select(state => state.subjectDetail)

      const { name } = subjectInfo || {};
      const { userContract } = classAndMusicInfo || {}
      const { remainClass } = userContract || {}

      const { offlinePlanNum: { remainingTimes }, independentCoachConfig, trainerCredentialses, currentlist } = yield select(state => state.subjectOfflinePlan);

      const findItem = currentlist.find(item => {
        const { userOfflineTrainingPlan } = item || {}
        const { id } = userOfflineTrainingPlan || {}
        return userPlanId === id
      })

      const { userOfflineTrainingPlan } = findItem || {}
      const { qualificationMedals = [], assessmentStatus = [], userId } = userOfflineTrainingPlan || {}

      const { current } = currentUser || {}
      const { roles = [] } = current || {}
      const { usedTimes = 0, totalTimes = 0 } = independentCoachConfig || {}
      const freeTimes = totalTimes - usedTimes;
      const isIndependentCoach = roles.some(role => role.name === 'IndependentCoach')
      const cardStatus = isActiveCredentiales(qualificationMedals, trainerCredentialses)
      const coachShowToast = cardStatus === 'Active' || cardStatus === 'NotActive' || cardStatus === 'Expired'

      if (coachShowToast && isIndependentCoach) {
        if (cardStatus === 'Expired') {
          message.warning('您的证书已过期，请联系您的培训负责人')
        } else {
          if (showCoachTimesMsg(intl, freeTimes, 'click')) return;
        }
      }

      if (
        showRemainingTimesMsg(
          intl,
          remainingTimes,
          currentUser,
          'offlinePlan',
          'click',
          () =>
            getDvaApp()._store.dispatch({
              type: 'subjectOfflinePlan/goSettings',
            }),
        )
      ) {
        return;
      }

      // 无存续状态的合同，则不能开始下一节
      if (
        showUserContractMsg(
          intl,
          userContract,
          currentUser,
          () =>
            getDvaApp()._store.dispatch({
              type: 'subjectDetail/updateState',
              payload: { addContractModalVisible: true },
            }),
        )
      ) {
        return;
      }

      // 剩余课时小于1时弹出提醒
      if (remainClass <= 1) {
        Modal.warning({
          title: intl.formatMessage(
            { id: 'remainClassTips' },
            {
              childList: name,
              remainList: (
                <span style={{ color: 'red' }}>
                  {remainClass}
                </span>
              ),
            },
          ),
          content: '',
        });
      }

      yield put({
        type: 'updateState',
        payload: {
          generatorModalVisible: true
        }
      })

      const res = yield call(GoToNextCourse, { offlinePlanId: userPlanId, num })
      if (res.response.ok) {
        message.success(intl.formatMessage({ id: 'success' }))
        yield put({
          type: 'loadList',
        })

        if (coachShowToast && isIndependentCoach) {
          if (cardStatus !== 'Expired') {
            if (showCoachTimesMsg(intl, freeTimes, 'submit'));
          }
        }
        showRemainingTimesMsg(intl, remainingTimes, currentUser, 'offlinePlan', 'submit', () => getDvaApp()._store.dispatch({ type: 'subjectOfflinePlan/goSettings' }))
      } else {
        const { data: { error } } = res;
        const { message: _message, code } = error || {};
        if (code === "RocketSystem:UncompletedMediumAssessment") {
          Modal.warning({
            title: _message,
            content: '',
            okText: intl.formatMessage({ id: 'evaluationQrCode' }),
            onOk() {
              const { assessment, userAssessment } =
                assessmentStatus[0] || {};
              let _userAssessment = {
                ...userAssessment,
              };
              const { id: assessmentId, title } =
                assessment || {};
              delete _userAssessment?.id;
              getDvaApp()._store.dispatch({
                type: 'subjectAssessment/getAssessmentQrCode',
                payload: {
                  record: {
                    ..._userAssessment,
                    assessmentName: title,
                    assessmentId,
                    userId
                  },
                  intl,
                  from: 'Retest'
                },
              })
            }
          });
        } else {
          message.error(_message);
        }
      }

      yield put({
        type: 'updateState',
        payload: {
          generatorModalVisible: false
        }
      })
    },

    *checkTryCourse({ payload }, { call, put, select }) {
      const { userPlanId, num, totalNum, intl } = payload
      yield put({
        type: 'updateState',
        payload: {
          endCourseLoading: true,
          currentProgramId: userPlanId
        }
      })
      const res = yield call(PostCheckTryCourse, { offlinePlanId: userPlanId, num })
      const { planInfo: musicPlanInfo } = yield select(state => state.musicplayer);
      const musicPlayerId = musicPlanInfo && musicPlanInfo.userOfflineTrainingPlan && musicPlanInfo.userOfflineTrainingPlan.id
      if (res.response.ok) {
        const { needTry, tryCount } = res.data
        if (needTry && tryCount < 500) {
          if (tryCount > 0) {
            Modal.confirm({
              title: intl.formatMessage({ id: 'onlyTrialsLeft' }, { tryCount: tryCount }),
              content: intl.formatMessage({ id: 'reasonablyAccording' }),
              okText: intl.formatMessage({ id: 'ok' }),
              cancelText: intl.formatMessage({ id: 'cancel' }),
              onOk() {
                Modal.confirm({
                  title: intl.formatMessage({ id: 'tips' }),
                  content: intl.formatMessage({ id: 'report.offlinePlanInfo.cancel.title' }),
                  okText: intl.formatMessage({ id: 'ok' }),
                  cancelText: intl.formatMessage({ id: 'cancel' }),
                  onOk() {
                    getDvaApp()._store.dispatch({ type: 'subjectOfflinePlan/finishCourse', payload: { userPlanId, num, totalNum, intl } })
                    if (userPlanId == musicPlayerId) {
                      getDvaApp()._store.dispatch({ type: 'musicplayer/closeGlobalMusicPlayer' })
                    }
                  },
                })
              },
            })
          }
          else {
            message.warning(intl.formatMessage({ id: 'noTrialsAvailable' }));
          }
        }
        else {
          Modal.confirm({
            title: intl.formatMessage({ id: 'tips' }),
            content: intl.formatMessage({ id: 'report.offlinePlanInfo.cancel.title' }),
            okText: intl.formatMessage({ id: 'ok' }),
            cancelText: intl.formatMessage({ id: 'cancel' }),
            onOk() {
              getDvaApp()._store.dispatch({ type: 'subjectOfflinePlan/finishCourse', payload: { userPlanId, num, totalNum, intl } })
              if (userPlanId == musicPlayerId) {
                getDvaApp()._store.dispatch({ type: 'musicplayer/closeGlobalMusicPlayer' })
              }
            },
          })
        }
      } else {
        const { data: { error } } = res;
        message.error(error.message);
      }

      yield put({
        type: 'updateState',
        payload: {
          endCourseLoading: false,
          currentProgramId: null
        }
      })
    },

    *hasCClass({ payload, callback }, { call, put, select }) {
      const res = yield call(HasCClasssBeforeStartUserOfflinePlan, payload.id);
      if (res.response.ok) {
        callback(res);
      } else {
        const { data: { error } } = res;
        message.error(error.message);
      }
    },

    *getLatelyCourse({ payload: { id } }, { call, put, select }) {
      const res = yield call(GetLatelyCourseApi, {
        planId: id
      });
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: { latelyCourse: res.data },
        })
      }
    },

    *courseAdjusment({ payload: { courses = [], cCourse = [], planId, intl } }, { call, put, select }) {
      const { allowReloadTab } = yield select(state => state.subjectOfflinePlan)

      const res = yield call(PostCourseAdjustmentApi, {
        courses,
        cCourse,
        planId
      });
      if (res.response.ok) {
        yield put({
          type: 'loadList',
        })
        yield put({
          type: 'updateState',
          payload: {
            classAdjustmentModalVisible: false,
            allowReloadTab: !allowReloadTab,
          }
        })
        message.success(intl.formatMessage({ id: 'success' }))
      } else {
        const { data: { error } } = res;
        message.error(error.message);
      }
    },

    *onGuardianFeedback({ payload: data }, { call, put, select }) {
      const { userPlanId, num } = data;
      const res = yield call(GetUserOfflinePlanForNumPunch, {
        UserOfflinePlanId: userPlanId,
        Num: num,
        ForGuardian: true
      })
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: { selectGuardianCourseInfo: res.data }
        })
        yield put({
          type: 'findActiveKeys',
          payload: { type: 'guardian', }
        })
      }
    },

    *onCategoryFeedback({ payload: data }, { call, put, select }) {
      const { userPlanId, num } = data;
      const res = yield call(GetCategoryPunch, {
        UserOfflinePlanId: userPlanId,
        Num: num,
        ForGuardian: false
      })
      if (res.response.ok) {
        const { feedbacks } = res.data || {};

        const _selectCategoryInfo = {
          id: 'category',
          hasDone: Object.keys(feedbacks || {}).length > 0,
          ...res.data,
          feedbacks: [
            feedbacks
          ]
        }

        yield put({
          type: 'updateState',
          payload: { selectCategoryInfo: _selectCategoryInfo }
        })
      }
    },

    *findActiveKeys({ payload }, { call, put, select }) {
      const { selectGuardianCourseInfo, selectCourseInfo } = yield select(state => state.subjectOfflinePlan)
      const { subjectInfo } = yield select(state => state.subjectDetail)
      const { currentUser } = yield select(state => state.user)
      const { audioRecordPunch, hasDone: guardianHasDone, userOfflineTrainingPlan } = selectGuardianCourseInfo || {}
      const { planItems, nonePlaybook } = selectCourseInfo || {}
      const { isBind, isLogout } = subjectInfo || {}

      const { auth, changedTenant } = currentUser || {}
      const { grantedPolicies } = auth || {}

      const rocketProjectStatus = changedTenant && changedTenant.projectStatus ? changedTenant.projectStatus.find(item => item.name == 'Project.Rocket')['status'] : true;
      const hasOfflinePlanWritePermission = grantedPolicies['RocketSystem.UserOfflinePlan.Write'] === true

      const musicHasDone = getMusicHasDone({ audioRecordPunch })

      const guardianId = getDisabledValue({ hasDone: guardianHasDone, rocketProjectStatus, isBind, isLogout, hasOfflinePlanWritePermission }) === false
        && getHasDoneValue({ hasDone: guardianHasDone, rocketProjectStatus, isBind, isLogout, hasOfflinePlanWritePermission })
        ? userOfflineTrainingPlan.id : null

      const musicId = audioRecordPunch !== null ?
        (getDisabledValue({ hasDone: musicHasDone, rocketProjectStatus, isBind, isLogout, hasOfflinePlanWritePermission }) === false
          && getHasDoneValue({ hasDone: musicHasDone, rocketProjectStatus, isBind, isLogout, hasOfflinePlanWritePermission }) ? 'music' : null) : null

      const { type, id } = payload
      let activeKey

      const nextKey = getNextKey({ planitems: planItems, nonePlayBooks: nonePlaybook, rocketProjectStatus, isBind, isLogout, hasOfflinePlanWritePermission })

      switch (type) {
        case 'guardian':
          activeKey =  musicId || nextKey || 'categoryFeedback'
          break
        case 'music':
          activeKey = nextKey || guardianId
          break
        default:
          if (id) {
            activeKey = getNextKey({ id, planitems: planItems, nonePlayBooks: nonePlaybook, rocketProjectStatus, isBind, isLogout, hasOfflinePlanWritePermission }) || nextKey || guardianId || musicId
          }
          else {
            activeKey = nextKey || guardianId || musicId
          }
          break
      }

      yield put({
        type: 'updateState',
        payload: {
          activeKey: activeKey !== null ? [activeKey] : []
        },
      })

    },

    *submitGuardianFeedback({ payload }, { call, put, select }) {
      const { submitData, guardianHasDone } = payload
      const res = yield call(guardianHasDone == null ? PostNumPunch : PutNumPunch, submitData);
      if (res.response.ok) {
        const { userOfflineTrainingPlan, num } = res.data
        yield put({
          type: 'onGuardianFeedback',
          payload: { userPlanId: userOfflineTrainingPlan.id, num },
        })
      } else {
        const { error } = res.data || {};
        message.error(error.message);
      }
    },

    *goSettings({ payload }, { call, put, take, select }) {
      yield put({ 
        type: 'updateState', 
        payload: { trainModalVisible: true } 
      })
    },

    *beforeCreate({ payload: { intl } }, { call, put, select }) {
      const { currentUser } = yield select(state => state.user)
      const { subjectId, subjectInfo } = yield select(state => state.subjectDetail);
      const { name, stage } = subjectInfo
      const { offlinePlanNum: { remainingTimes }, currentlist, independentCoachConfig } = yield select(state => state.subjectOfflinePlan);

      const { current } = currentUser || {}
      const { roles = [] } = current || {}
      const { usedTimes = 0, totalTimes = 0 } = independentCoachConfig || {}
      const isIndependentCoach = roles.some(role => role.name === 'IndependentCoach')

      yield put({
        type: 'updateState',
        payload: {
          newPlanLoading: true,
        },
      })

      if (stage === '黄金时代') {
        const res = yield call(PostBeforeCreate, { subjectId });
        if (res.response.ok) {
          const { planKeys } = res.data || {}
          const currentType = planKeys && planKeys.length > 0 ? planKeys[0].code : ''
          yield put({
            type: 'updateState',
            payload: {
              brainModalVisible: true,
              brainType: res.data,
              currentType
            },
          })
        } else {
          const { data: { error } } = res;
          message.error(error.message);
        }
      } else {
        if (showRemainingTimesMsg(intl, remainingTimes, currentUser, 'offlinePlan', 'click', () => getDvaApp()._store.dispatch({ type: 'subjectOfflinePlan/goSettings' }))) {
          yield put({
            type: 'updateState',
            payload: {
              newPlanLoading: false,
            },
          })
          return
        }

        const res = yield call(PostBeforeCreate, { subjectId });
        if (res.response.ok) {
          const { planKeys } = res.data || {}
          let newPlanKeys = [];
          planKeys.forEach(plan => {
            const { displayName } = plan || {}
            const findRes = currentlist.find(current => {
              const { userOfflineTrainingPlan } = current || {};
              const { displayName: currentDisplayName } = userOfflineTrainingPlan || {};
              return displayName === currentDisplayName
            });

            if (findRes === undefined) newPlanKeys.push(plan)
          })
          const currentType = newPlanKeys && newPlanKeys.length > 0 ? newPlanKeys[0].code : ''
          yield put({
            type: 'updateState',
            payload: {
              brainModalVisible: true,
              brainType: res.data,
              currentType
            },
          })
        } else {
          const { data: { error } } = res;
          message.error(error.message);
        }
      }
      yield put({
        type: 'updateState',
        payload: {
          newPlanLoading: false,
        },
      })
    },

    // *beforeCreate({ payload: { intl } }, { call, put, select }) {
    //   const { currentUser } = yield select(state => state.user)
    //   const { subjectId, subjectInfo } = yield select(state => state.subjectDetail);
    //   const { name, stage } = subjectInfo
    //   const { offlinePlanNum: { remainingTimes }, currentlist, independentCoachConfig } = yield select(state => state.subjectOfflinePlan);

    //   const { current } = currentUser || {}
    //   const { roles = [] } = current || {}
    //   const { usedTimes = 0, totalTimes = 0 } = independentCoachConfig || {}
    //   const isIndependentCoach = roles.some(role => role.name === 'IndependentCoach')

    //   yield put({
    //     type: 'updateState',
    //     payload: {
    //       newPlanLoading: true,
    //     },
    //   })
      
    //   if (stage === '黄金时代') {
    //     const res = yield call(PostBeforeCreate, { subjectId });
    //     if (res.response.ok) {
    //       const { planKeys } = res.data || {}
    //       const currentType = planKeys && planKeys.length > 0 ? planKeys[0].code : ''
    //       yield put({
    //         type: 'updateState',
    //         payload: {
    //           brainModalVisible: true,
    //           brainType: res.data,
    //           currentType
    //         },
    //       })
    //     } else {
    //       const { data: { error } } = res;
    //       message.error(error.message);
    //     }
    //   } else {
    //     const resList = yield [
    //       call(GetDiagnosticInformationList, {
    //         UserId: subjectId
    //       }),
    //       call(GetChiefComplaintList, {
    //         UserId: subjectId
    //       })
    //     ]
    //     if (resList.every(res => res.response.ok)) {

    //       // if (isIndependentCoach) {
    //       //   const freeTimes = totalTimes - usedTimes;
    //       //   if (showCoachTimesMsg(intl, freeTimes, 'click')) {
    //       //     yield put({
    //       //       type: 'updateState',
    //       //       payload: {
    //       //         newPlanLoading: false,
    //       //       },
    //       //     })
    //       //     return
    //       //   };
    //       // }

    //       if (showRemainingTimesMsg(intl, remainingTimes, currentUser, 'offlinePlan', 'click', () => getDvaApp()._store.dispatch({ type: 'subjectOfflinePlan/goSettings' }))) {
    //         yield put({
    //           type: 'updateState',
    //           payload: {
    //             newPlanLoading: false,
    //           },
    //         })
    //         return
    //       }

    //       const firstRes = resList[0].data.items.totalCount
    //       const twoRes = resList[1].data.items.totalCount

    //       if (firstRes === 0 || twoRes === 0) {
    //         Modal.confirm({
    //           title: intl.formatMessage({ id: 'fillNameProfile' }, { name }),
    //           width: 480,
    //           icon: <ExclamationCircleOutlined />,
    //           content: <div style={{ color: 'gray' }}>
    //             {intl.formatMessage({ id: 'informationDes' })}
    //           </div>,
    //           okText: intl.formatMessage({ id: 'fillOut' }),
    //           cancelText: intl.formatMessage({ id: 'cancel' }),
    //           onOk() {
    //             window.open(`/system/archivesV2?subjectId=${subjectId}`)
    //           }
    //         });
    //       } else {
    //         const res = yield call(PostBeforeCreate, { subjectId });
    //         if (res.response.ok) {
    //           const { planKeys } = res.data || {}
    //           let newPlanKeys = [];
    //           planKeys.forEach(plan => {
    //             const { displayName } = plan || {}
    //             const findRes = currentlist.find(current => {
    //               const { userOfflineTrainingPlan } = current || {};
    //               const { displayName: currentDisplayName } = userOfflineTrainingPlan || {};
    //               return displayName === currentDisplayName
    //             });

    //             if (findRes === undefined) newPlanKeys.push(plan)
    //           })
    //           const currentType = newPlanKeys && newPlanKeys.length > 0 ? newPlanKeys[0].code : ''
    //           yield put({
    //             type: 'updateState',
    //             payload: {
    //               brainModalVisible: true,
    //               brainType: res.data,
    //               currentType
    //             },
    //           })
    //         } else {
    //           const { data: { error } } = res;
    //           message.error(error.message);
    //         }
    //       }
    //     }
    //   }
    //   yield put({
    //     type: 'updateState',
    //     payload: {
    //       newPlanLoading: false,
    //     },
    //   })
    // },

    *updateCurrentlist({ payload: _ }, { call, put, select }) {
      const { currentlist } = JSON.parse(JSON.stringify(yield select(state => state.subjectOfflinePlan)));
      const { isOpen: isOpening, planInfo, currentIndex } = yield select(state => state.musicplayer)
      const id = planInfo.userOfflineTrainingPlan && planInfo.userOfflineTrainingPlan.id

      currentlist && currentlist.length > 0 && currentlist.forEach(item => {
        item.isOpening = false;
      })
      const hasPlanInfo = currentlist && currentlist.length > 0 && currentlist.find(item => {
        return item.userOfflineTrainingPlan.id == id
      })
      if (hasPlanInfo) {
        hasPlanInfo.isOpening = isOpening;
        hasPlanInfo.iLs.tracks.forEach(item => {
          item.isOpening = false;
        });
        hasPlanInfo.iLs.tracks[currentIndex].isOpening = isOpening
        yield put({
          type: 'updateState',
          payload: {
            currentlist
          }
        })
      }
    },

    *getBrainRecord({ payload: { id } }, { call, put, select }) {
      const res = yield call(GetBrainRecord, { UserOfflineTrainingPlanId: id });
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: {
            brainRecord: res.data,
          },
        })
      }
    },

    *arrangeAssessment({ payload }, { call, put, select }) {
      const { arrangeAssessmentModalVisible, currentArrangeAssessmentPlan } = payload;
      const res = yield call(GetArrangeAssessmentApi, { userPlanId: currentArrangeAssessmentPlan.id });
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: {
            arrangeAssessmentModalVisible,
            currentArrangeAssessmentPlan,
            arrangeAssessmentData: res.data
          },
        })
      }
    },

    *submitArrangeAssessment({ payload: { intl } }, { call, put, select }) {
      const { currentArrangeAssessmentPlan } = yield select(state => state.subjectOfflinePlan)
      const res = yield call(PostArrangeAssessmentApi, {
        userOfflinePlanId: currentArrangeAssessmentPlan.id
      })
      if (res.response.ok) {
        message.success(intl.formatMessage({ id: 'assessmentSuccessfully' }))
        yield put({ type: 'loadList' })
      }
    },

    *endPlan({ payload: { intl, reloadPage, values } }, { call, put, select }) {
      const { currentEndPlan } = yield select(state => state.subjectOfflinePlan)
      const res = yield call(PostEndPlanApi, {
        userOfflineTrainingPlanId: currentEndPlan.id,
        ...values
      });
      if (res.response.ok) {
        message.success(intl.formatMessage({ id: 'trainingPlanOver' }));
        yield put({ type: 'loadList' })
        if (reloadPage) reloadPage();
      }
    },

    *musicFeedback({ payload }, { call, put, select }) {
      const { selectGuardianCourseInfo } = yield select(state => state.subjectOfflinePlan)
      const res = yield call(PostMusicFeedback, payload.data)
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: {
            selectGuardianCourseInfo: Object.assign({}, selectGuardianCourseInfo, { audioRecordPunch: res.data })
          }
        })
        yield put({
          type: 'findActiveKeys',
          payload: { type: 'music', }
        })
      } else {
        const { data: { error } } = res;
        message.error(error.message);
      }
    },

    *showChartsAndFeedbackRecords({ payload }, { call, put, select }) {
      const { recordPagination } = yield select(state => state.subjectOfflinePlan)
      yield put({
        type: 'updateState',
        payload: {
          ilsDrawerVisible: true,
          currentTablePlan: payload,
          ilsActiveKey: 'chart',
          shouldGetData: true,
          ilsparentRecord: null,
          ilstrainerRecord: null,
          ilsappRecord: null,
          brainRecord: null,
          musicRecord: null,
          recordPagination: Object.assign(
            {},
            recordPagination,
            {
              ilstrainer: {
                current: 0,
                pageSize: 10,
              },
              ilsparent: {
                current: 0,
                pageSize: 10,
              },
              ilsapp: {
                current: 0,
                pageSize: 10,
              }
            }
          )
        }
      })
    },

    *getMusicRecord({ payload }, { call, put, select }) {
      const { id } = payload
      const res = yield call(GetMusicRecord, id)
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: {
            musicRecord: res.data
          }
        })
      }
    },

    *getTrainingCalendarList({ payload }, { call, put, select }) {
      const { currentArrangeCoursePlanInfo } = yield select(state => state.subjectOfflinePlan)
      const { userOfflineTrainingPlan } = currentArrangeCoursePlanInfo || {}
      const { id } = userOfflineTrainingPlan || {}
      const params = {
        userTrainingPlanId: id
      }
      const getTrainingCalendarRes = yield call(GetTrainingCalendarList, params)
      if (getTrainingCalendarRes.response.ok) {
        yield put({
          type: 'updateState',
          payload: {
            arrangeCourseDrawerVisible: true,
            arrangeCourseList: getTrainingCalendarRes.data
          }
        })
      }
    },

    *arrangeCourse({ payload }, { call, put, select }) {
      yield put({ type: 'updateState', payload: { currentArrangeCoursePlanInfo: payload } })
      yield put({ type: 'getTrainingCalendarList' })
    },

    *extendClass({ payload }, { call, put, select }) {
      yield put({ 
        type: 'updateState', 
        payload: { 
          currentClassItem: payload, 
          extendModalVisible: true 
        } 
      })
    },

    *extendClassOk({ payload }, { call, put, select }) {
      const { intl, params, callback } = payload;
      const res = yield call(PostExtendClass, params)
      if (res.response.ok) {
        message.success(intl.formatMessage({ id: 'success' }))
        yield put({ 
          type: 'updateState', 
          payload: { 
            extendModalVisible: false 
          } 
        })
        yield put({ type: 'loadList' })
        if (callback) callback()
      } else {
        const { data: { error } } = res;
        message.error(error.message);
      }
    },

    *addCourse({ payload }, { call, put, select }) {
      const { currentUser } = yield select((state) => state.user);
      const { auth, staff } = currentUser || {};
      const { grantedPolicies } = auth || {};
      const isTrainer =
        grantedPolicies['Business.Calendar'] &&
        grantedPolicies['Business.Calendar.Read'];
      const isCenter =
        grantedPolicies['Business.Calendar'] &&
        grantedPolicies['Business.Calendar.ReadOther'];
      const { subjectId } = yield select((state) => state.subjectDetail);
      const content = deepCopy(contentMenu());
      // 如果当前权限为center就去获取当前中心下的训练师
      if (isCenter) {
        const getTrainerListRes = yield call(GetSubjectLinkStaff, { subjectId })
        if (getTrainerListRes.response.ok) {
          const data = getTrainerListRes.data
          content.Properties[1].Setting.DropdownOptions = data.map(item => {
            const { name, id, email } = item || {}
            return {
              Value: name || email,
              Id: id
            }
          })
        }
      } else {
        if (isTrainer) {
          const { email, name, id } = staff || {}
          content.Properties[1].Setting.DropdownOptions = [
            {
              Value: name || email,
              Id: id
            }
          ]
        }
      }

      // 重置弹框内容
      content.Properties[0].Value = moment().set("minutes", 0)
      content.Properties[1].Value = null
      content.Properties[2].Value = null
      content.Properties[3].Value = null
      content.Properties[3].Setting.DropdownOptions[0].Disabled = false
      content.Properties[4].Value = 1
      content.Properties[4].Setting.IsVisible = true
      content.Properties[5].Value = ['parentSMS', 'trainerSMS']

      yield put({
        type: 'updateState',
        payload: {
          arrangeCourseModalVisible: true,
          arrangeCourseType: 'add',
          arrangeCourseContent: content
        }
      })
    },

    *submitCourse({ payload: { value, intl } }, { call, put, select }) {
      const { SMSReminder, courseCount, courseType, interval, startTime, trainer, parentAppNotice } = value || {}
      const { periodIntervalUnit, periodIntervalNumber } = getTimeSpan(interval) || {}
      const endTime = moment(startTime).add(1, 'hours').format()
      const { arrangeCourseType, currentArrangeCoursePlanInfo, currentEditCourse } = yield select(state => state.subjectOfflinePlan)
      const { currentUser } = yield select(state => state.user)
      const { subjectInfo } = yield select(state => state.subjectDetail)
      const { current } = currentUser || {}
      const { id } = current || {}
      const { id: subjectId } = subjectInfo || {}
      const { userOfflineTrainingPlan } = currentArrangeCoursePlanInfo || {}
      const { id: userTrainingPlanId } = userOfflineTrainingPlan || {}
      if (moment(startTime).isSameOrBefore(moment())) {
        message.error(intl.formatMessage({ id: 'startMoreEnd' }))
        return
      }
      if (arrangeCourseType === "add") {
        const postData = {
          startTime,
          endTime,
          calendarEventType: 'Training',
          tenantId: id,
          executorId: trainer,
          subjectId,
          courseType,
          periodIntervalUnit,
          periodIntervalNumber,
          parentBeforeHours: SMSReminder.includes("parentSMS") ? 24 : null,
          executorBeforeHours: SMSReminder.includes("trainerSMS") ? 24 : null,
          parentSMS: SMSReminder.includes("parentSMS"),
          trainerSMS: SMSReminder.includes("trainerSMS"),
          courseCount,
          userTrainingPlanId,
          parentAppNotice
        }

        const submitCourseRes = yield call(PostArrangeCourse, postData, { 't-select': id })
        if (submitCourseRes.response.ok) {
          yield put({
            type: 'updateState',
            payload: {
              arrangeCourseModalVisible: false
            }
          })
          yield put({ type: 'getTrainingCalendarList' })
        }
      } else if (arrangeCourseType === "edit") {
        const postData = {
          startTime,
          endTime,
          calendarEventType: 'Training',
          tenantId: id,
          executorId: trainer,
          subjectId,
          courseType,
          periodIntervalUnit,
          periodIntervalNumber,
          parentBeforeHours: SMSReminder.includes("parentSMS") ? 24 : null,
          executorBeforeHours: SMSReminder.includes("trainerSMS") ? 24 : null,
          parentSMS: SMSReminder.includes("parentSMS"),
          trainerSMS: SMSReminder.includes("trainerSMS"),
          courseCount: courseCount || 0,
          userTrainingPlanId,
          currentIndex: 0,
          id: currentEditCourse && currentEditCourse.id,
          parentAppNotice
        }

        const putCourseRes = yield call(PutCalendar, postData, { 't-select': id })
        if (putCourseRes.response.ok) {
          yield put({
            type: 'updateState',
            payload: {
              arrangeCourseModalVisible: false
            }
          })
          yield put({ type: 'getTrainingCalendarList' })
        }
      }
    },

    *editCourse({ payload }, { call, put, select }) {
      const {
        startTime,
        executor,
        calendarCourseType,
        periodIntervalNumber,
        periodIntervalUnit,
        calendarCount,
        parentBeforeHours,
        executorBeforeHours } = payload || {}

      const content = JSON.parse(JSON.stringify(contentMenu()))
      // 开始上课时间
      content.Properties[0].Value = startTime
      // 为训练师赋值
      const { currentUser } = yield select(state => state.user)
      const { auth, staff } = currentUser || {}
      const { grantedPolicies } = auth || {}
      const isTrainer = grantedPolicies["Business.Calendar"] && grantedPolicies["Business.Calendar.Read"]
      const isCenter = grantedPolicies["Business.Calendar"] && grantedPolicies["Business.Calendar.ReadOther"]
      const { subjectId } = yield select(state => state.subjectDetail);
      // 如果当前权限为center就去获取当前中心下的训练师
      if (isCenter) {
        const getTrainerListRes = yield call(GetSubjectLinkStaff, { subjectId })
        if (getTrainerListRes.response.ok) {
          const data = getTrainerListRes.data
          content.Properties[1].Setting.DropdownOptions = data.map(item => {
            const { name, id, email } = item || {}
            return {
              Value: name || email,
              Id: id
            }
          })
        }
      } else {
        if (isTrainer) {
          const { email, name, id } = staff || {}
          content.Properties[1].Setting.DropdownOptions = [
            {
              Value: name || email,
              Id: id
            }
          ]
        }
      }

      content.Properties[1].Value = executor && executor.id

      // 线上还是线下
      content.Properties[2].Value = calendarCourseType

      // 重复排课
      const teachSchadule = getRepetitiveStyle(periodIntervalNumber, periodIntervalUnit)
      content.Properties[3].Value = teachSchadule

      // 重复次数
      if (teachSchadule == 0) {
        content.Properties[3].Setting.DropdownOptions[0].Disabled = false
        content.Properties[4].Setting.IsVisible = false
      } else {
        content.Properties[3].Setting.DropdownOptions[0].Disabled = true
        content.Properties[4].Setting.IsVisible = true
        content.Properties[4].Value = calendarCount
      }

      // 短信提醒
      content.Properties[5].Value = [parentBeforeHours ? 'parentSMS' : '', executorBeforeHours ? 'trainerSMS' : '']

      yield put({
        type: 'updateState',
        payload: {
          arrangeCourseContent: content,
          arrangeCourseModalVisible: true,
          arrangeCourseType: 'edit',
          currentEditCourse: payload
        }
      })
    },

    *deleteCourse({ payload: { values, intl } }, { call, put, select }) {
      const { id } = values
      const params = {
        Id: id
      }
      const deleteCourseRes = yield call(DeleteCalendar, params)
      if (deleteCourseRes.response.ok) {
        message.success(intl.formatMessage({ id: 'deletedSuccess' }))
        yield put({ type: 'getTrainingCalendarList' })
      } else {
        message.error(intl.formatMessage({ id: 'failed' }))
      }
    },

    *onChangeCollapse({ payload }, { call, put, select }) {
      const { selectGuardianCourseInfo, selectCourseInfo } = JSON.parse(JSON.stringify(yield select(state => state.subjectOfflinePlan)))

      const { activeKey, type, hasDone } = payload || {}
      const { userOfflineTrainingPlan, audioRecordPunch } = selectGuardianCourseInfo || {}
      let { planItems, nonePlaybook } = selectCourseInfo || {}

      const { id: userOfflineTrainingPlanId, currentNum } = userOfflineTrainingPlan || {}

      yield put({
        type: 'updateState',
        payload: {
          activeKey
        }
      })

      if (type === 'guardian') {
        // 代表已做
        if (hasDone == true) {
          selectGuardianCourseInfo.isEdit = false
        } else {
          selectGuardianCourseInfo.isEdit = true
        }
        yield put({
          type: 'updateState',
          payload: {
            selectGuardianCourseInfo
          }
        })
      }


      if (type === "activity") {
        let current = null
        planItems = planItems.map(item => {
          if (item.id === (activeKey && activeKey.length && activeKey[activeKey.length - 1])) {
            const { hasDone } = item || {}
            current = item
            if (hasDone == true) {
              item.isEdit = false
            } else {
              item.isEdit = true
            }
          }
          return item
        })
        if (!current) {
          nonePlaybook = nonePlaybook.map(item => {
            if (item.id === (activeKey && activeKey.length && activeKey[activeKey.length - 1])) {
              const { hasDone } = item || {}
              current = item
              if (hasDone == true) {
                item.isEdit = false
              } else {
                item.isEdit = true
              }
            }
            return item
          })
        }
        yield put({
          type: 'updateState',
          payload: {
            selectCourseInfo: {
              ...selectCourseInfo,
              planItems,
              nonePlaybook
            }
          }
        })
      }

      if (type === 'category') {
        yield put({
          type: 'onCategoryFeedback',
          payload: { userPlanId: userOfflineTrainingPlanId, num: currentNum },
        })
      }

      if (type === 'music') {
        const { punched, listenedList } = audioRecordPunch || {}
        let musicHasDone = null
        if (punched === false) {
          musicHasDone = null
        }
        if (punched && listenedList.length === 0) {
          musicHasDone = false
        }
        if (punched && listenedList.length > 0) {
          musicHasDone = true
        }
        if (musicHasDone == true) {
          selectCourseInfo.musicIsEdit = false
        } else {
          selectCourseInfo.musicIsEdit = true
        }
        yield put({
          type: 'updateState',
          payload: {
            selectCourseInfo
          }
        })
      }
    },
    *eidtItem({ payload }, { call, put, select }) {
      const { selectGuardianCourseInfo, selectCourseInfo, selectCategoryInfo } = JSON.parse(JSON.stringify(yield select(state => state.subjectOfflinePlan)))
      const { userOfflineTrainingPlan, audioRecordPunch } = selectGuardianCourseInfo || {}
      const { data, type } = payload || {}
      let { planItems, nonePlaybook } = selectCourseInfo || {}
      if (type === "guardian") {
        const { id } = userOfflineTrainingPlan || {}
        selectGuardianCourseInfo.isEdit = true
        yield put({
          type: 'updateState',
          payload: {
            activeKey: id
          }
        })
        yield put({
          type: 'updateState',
          payload: {
            selectGuardianCourseInfo
          }
        })
      }

      if (type === "activity") {
        const { hasDone, id } = data || {}
        yield put({
          type: 'updateState',
          payload: {
            activeKey: id
          }
        })
        if (hasDone == true) {
          planItems = planItems.map(item => {
            if (item.id === id) {
              item.isEdit = true
            }
            return item
          })
          nonePlaybook = nonePlaybook.map(item => {
            if (item.id === id) {
              item.isEdit = true
            }
            return item
          })
        } else {
          planItems = planItems.map(item => {
            if (item.id === id) {
              item.isEdit = false
            }
            return item
          })
          nonePlaybook = nonePlaybook.map(item => {
            if (item.id === id) {
              item.isEdit = false
            }
            return item
          })
        }
        yield put({
          type: 'updateState',
          payload: {
            selectCourseInfo: {
              ...selectCourseInfo,
              planItems,
              nonePlaybook
            }
          }
        })
      }

      if (type === 'category') {
        selectCategoryInfo.isEdit = true

        yield put({
          type: 'updateState',
          payload: {
            selectCategoryInfo,
            activeKey: 'categoryFeedback'
          }
        })
      }

      if (type === "music") {
        const { punched, listenedList } = audioRecordPunch || {}
        let musicHasDone = null
        if (punched === false) {
          musicHasDone = null
        }
        if (punched && listenedList.length === 0) {
          musicHasDone = false
        }
        if (punched && listenedList.length > 0) {
          musicHasDone = true
        }

        if (musicHasDone == true) {
          selectCourseInfo.musicIsEdit = true
        } else {
          selectCourseInfo.musicIsEdit = false
        }

        yield put({
          type: 'updateState',
          payload: {
            activeKey: 'music'
          }
        })

        yield put({
          type: 'updateState',
          payload: {
            selectCourseInfo
          }
        })
      }
    },

    *reStart({ payload: { intl, id, callbackFn }}, { call, put, select }) {
      const { currentUser } = yield select(state => state.user)
      const { classAndMusicInfo } = yield select(state => state.subjectDetail)

      const { userContract } = classAndMusicInfo || {}

      // 无存续状态的合同，则不能开始下一节
      if (
        showUserContractMsg(
          intl,
          userContract,
          currentUser,
          () =>
            getDvaApp()._store.dispatch({
              type: 'subjectDetail/updateState',
              payload: { addContractModalVisible: true },
            }),
        )
      ) {
        return;
      }

      const res = yield call(PostRestartPlanApi, {
        userOfflineTrainingPlanId: id
      });
      if (res.response.ok) {
        message.success(intl.formatMessage({ id: 'successStart' }));
        yield put({ type: 'loadList' });
        callbackFn && callbackFn();
      } else {
        const { data: { error } } = res;
        message.error(error.message);
      }
    },
  },

  subscriptions: {
    setup({ dispatch, history }) {
      // Warning: [app.unmodel] subscription should return unlistener function, check these subscriptions setup
      return () => { }
    },
  },
}
